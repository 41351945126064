import React, { useContext } from 'react';
import styled from 'styled-components';
import { Footer, SEO } from 'components';
import { LocalContext } from 'context';
import { flexContainer, pfizer, Section } from 'styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { EnitiativesLogoContrast } from 'assets/svgs';
import { motion } from 'framer-motion';

const Privacy = ({ data }) => {
  const { headerRef } = useContext(LocalContext);
  const colors = pfizer;
  const backgroundImage = getImage(data.background);
  return (
    <>
      <SEO pageSpecificTitle="Privacy Policy" />
      <Header
        ref={headerRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}>
        <Background>
          <BackgroundImage image={backgroundImage} alt="Background" />
        </Background>
        <Container>
          <Content>
            <AnimatedTitle
              initial={{ transform: 'rotateX(90deg)' }}
              animate={{ transform: 'rotateX(0deg)' }}
              transition={{ duration: 1, delay: 1 }}>
              Privacy Policy
            </AnimatedTitle>
            <Sponsor
              colors={colors}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              <p>brought to you by</p>
              <EnitiativesLogoContrast />
            </Sponsor>
            <Description
              colors={colors}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              Welcome to the Pfizer Virtual Café. Register here for Pfizer&apos;s series of
              educational webinars on a broad range of therapeutic areas delivered by recognised
              Healthcare experts.
            </Description>
          </Content>
        </Container>
      </Header>
      <Main colors={colors}>
        <MainContent>
          <h2>Introduction</h2>
          <p>
            Agency X Limited operating as <strong>PfizerVirtualCafe.ie</strong>
          </p>
          This document outlines our approach to compliance with the General Data Protection
          Regulation (GDPR) and all other relevant data protection legislation.
          <p>
            The aim of this Privacy Policy document is to explain how we deal with the data you
            provide to us while visiting our website (https://PfizerVirtualCafe.ie) and also when
            registering to attend one of our virtual events or webinars.
          </p>
          <h3>Why we collect your personal information</h3>
          <h4>To respond to a query</h4>
          <p>
            When you contact Pfizervirtualcafe.ie, we need to know your name, email address and
            query so that we can respond in an appropriate manner. All queries are dealt with by
            someone from Pfizervirtualcafe.ie and there is no third-party involved. Under no
            circumstances will we ever pass on your details or use it for any other purpose than to
            respond to your query.
          </p>
          <h4>To Register for a Virtual Conference or Webinar</h4>
          <p>
            When you register for a Virtual Conference or webinar we need to collect personal
            information (typically your Name and Email) in order for us to verify your details and
            send you a link to join the webinar. We may send you reminder information about an
            upcoming webinar and/or follow-up information about the webinar.
          </p>
          <h4>To Provide Services during a Virtual Conference or Webinar</h4>
          <p>
            During the Virtual Conference or Webinar we may collect information that you have
            submitted in the form of a question, poll results and surveys. This information is only
            ever kept for the purposes of facilitating the Virtual Conference or Webinar and is only
            kept in anonymous format once the webinar has finished for statistical purposes.
          </p>
          <h3>Website Information Collected</h3>
          <p>
            This document refers to the PfizerVirtualCafe.ie website and any associated web pages
            used to manage the registration and delivery of PfizerVirtualCafe.ie services. Any
            external links to other websites are clearly identifiable as such, and we are not
            responsible for the content or the privacy policies of any of these other sites.
          </p>
          <h4>Types of Information Collected</h4>
          <p>
            On the PfizerVirtualCafe.ie website we retain two different types of information from
            visitors:
          </p>
          <ul>
            <li>
              Personal Data: This is data that identifies you or can be used to identify or contact
              you and may include your name, postal address, email address and telephone number.
              Such information is only collected from you if you voluntarily submit it to us, for
              example, through our ‘Contact Us’ email facility or by completing one of our online
              forms.
            </li>

            <li>
              Non-Personal Data: Like most websites, we gather statistical and other analytical
              information collected on an aggregate basis of all visitors to our site. This
              Non-Personal Data comprises information that cannot be used to identify or contact
              you, such as demographic information regarding, for example, browser types and other
              anonymous statistical data involving the use of our website.
            </li>
          </ul>
          <h3>Purposes for which we hold your information</h3>
          <ul>
            <li>
              Non-Personal Data: We use the Non-Personal Data gathered from visitors to our website
              in an aggregate form to get a better understanding of where our visitors come from and
              to help us offer a better user experience.
            </li>

            <li>
              Cookies: A Cookie is a very small text document, which often includes an anonymous
              unique identifier. When you visit a website, that site’s computer asks your computer
              for permission to store this file in a part of your hard drive specifically designated
              for Cookies.
            </li>
          </ul>
          <p>
            Each website can send its own Cookie to your browser if your browser’s preferences allow
            it, but (to protect your privacy) your browser only permits a website to access the
            Cookies it has already sent to you, not the Cookies sent to you by other sites.
          </p>
          <p>
            We use Cookies to give you the best possible online user experience. Third party cookies
            are set on behalf of PfizerVirtualCafe.ie. These partner cookies do not collect personal
            data and would not be able to identify you as an individual customer.
          </p>
          <h4>In summary, cookies are used:</h4>
          <ol>
            <li>For technical purposes essential to the effective operation of websites.</li>

            <li>
              To enable PfizerVirtualCafe.ie to collect anonymous information about the browsing
              activities of our clients for internal analysis.
            </li>

            <li>
              To drive PfizerVirtualCafe.ie marketing activities. For example, third party cookies
              may be used to show you relevant advertisements on other websites you visit.
            </li>
          </ol>
          <h3>Managing Your Personal Data</h3>
          <p>We will process any Personal Data you provide to us for the following purposes:</p>
          <ul>
            <li>To provide you with information about Pfizervirtualcafe.ie</li>

            <li>
              To contact you if required in connection with your enquiry or to respond to any
              communications you might send to us.
            </li>

            <li>
              Any communications submitted online or by email will be subject to the same terms and
              conditions as those completed by hand in hard copy form. If any online application
              forms are included in our website, you will be notified of any additional specific
              data protection provisions at the time you complete the relevant application form.
            </li>
          </ul>
          <h3>PfizerVirtualCafe.ie and Data Processor</h3>
          <p>
            Pfizervirtualcafe.ie will engage certain service providers to perform certain services
            on its behalf which may involve the Processing of Personal Data, for example third
            parties that assist with distribution of Pfizervirtualcafe.ie newsletters and emails. To
            the extent that such process is undertaken based on the instructions of
            Pfizervirtualcafe.ie and gives rise to a Data Controller and Data Processor
            relationship, Pfizervirtualcafe.ie will ensure that such relationship is governed by a
            contract which includes the data protection provisions prescribed by Data Protection
            Law.
          </p>
          <h3>Security of information</h3>
          <p>
            We have technical and organisational measures in place to protect Personal Data from
            unlawful or unauthorised destruction, loss, change, disclosure, acquisition or access.
            Personal Data is held securely using a range of security measures including restricted
            access through approvals and passwords.
          </p>
          <h3>Non-disclosure to third parties</h3>
          <p>
            If personally identifying data about you may be shared with a partner company, we will
            tell you before we collect or transfer the data so that you may opt out of this transfer
            if you wish. We use your information only as set out in this Policy and will not sell,
            rent, or pass your information on to others for marketing purposes without your consent.
          </p>
          <p>
            Pfizervirtualcafe.ie may however provide aggregate statistics about its supporters,
            traffic patterns and related site information to reputable third-party vendors and
            relevant affiliate partners, but these statistics will include no personally identifying
            information. We may also release account information where we believe in good faith that
            this release is reasonably necessary to comply with the law.
          </p>
          <h3>The accuracy of your information</h3>
          <p>
            While we will make all attempts to ensure that the information we hold about you is
            accurate and, where necessary, kept up to date, we shall assume that in the absence of
            evidence to the contrary, the information you provide us with is accurate. If you inform
            us, or we become aware, of any inaccuracies in the information it shall be promptly
            rectified by us. We will not retain any information about you which is out of date or
            which is no longer required.
          </p>
          <h3>Users 16 years and under</h3>
          <p>
            If you are aged 16 or under, you MUST obtain your parent/guardian’s permission
            beforehand whenever you provide personal information to our website. Users without this
            consent are not allowed to provide us with personal information.
          </p>
          <p>
            These Data Subject Rights will be exercisable by you subject to limitations as provided
            for under Data Protection Law. You may make a request to Pfizervirtualcafe.ie to
            exercise any of the Data Subject Rights by contacting Pfizervirtualcafe.ie on
            privacy@PfizerVirtualCafe.ie
          </p>
          <h3>Data Storage and Retention</h3>
          <p>
            We will retain your personal information as needed to fulfil the purposes for which it
            was collected. We will retain and use your personal information no longer than is
            necessary to comply with our business and legal obligations. Where we enter into a legal
            contract with you, we will hold your personal information for a period of seven years
            from the date of completion of this contract by reference to the statute of limitation
            for a legal claim under contract.
          </p>
          <h3>Changes to the Privacy Policy</h3>
          <p>
            Any changes to this Privacy Policy will be posted on our website so you are always aware
            of what information we collect, how we use it, and under what circumstances, if any, we
            disclose it.
          </p>
          <p>
            If at any time we decide to use Personal Data in a manner significantly different from
            that stated in this Privacy Policy, or otherwise disclosed to you at the time it was
            collected, we will notify you by email, and you will have a choice as to whether or not
            we use your information in the new manner.{' '}
          </p>
          <h3>Your Data Rights</h3>
          <p>
            If you have any questions regarding this Policy, please contact us at
            privacy@PfizerVirtualCafe.ie.
          </p>
          <p>
            You have several rights under data-protection law in relation to how we use your
            personal information. You have the right, free of charge, to:{' '}
          </p>
          <ul>
            <li>
              Request a copy of the personal information we hold about you in a structured, commonly
              used and machine-readable format
            </li>

            <li>Rectify any inaccurate personal information we hold about you</li>

            <li>
              Withdraw your consent where we have relied upon your consent to process your
              information
            </li>

            <li>Erase the personal information we hold about you subject to certain exceptions</li>

            <li>
              If technically feasible, have your personal information transmitted to another data
              controller in a machine-readable format at your request
            </li>

            <li>Restrict processing of your personal information in certain circumstances</li>

            <li>
              Object to our use of your personal information for our legitimate interests, for
              profiling and for direct marketing purposes
            </li>

            <li>
              Not be subject to a decision which is based solely on automated processing where that
              decision produces a legal effect on you or otherwise significantly affects you. We do
              not make automated decisions of this nature
            </li>

            <li>
              <strong>Lodge a complaint</strong> with the Data Protection Commission authority if
              you have concerns about how we process your personal data
            </li>
          </ul>
          <p>
            These rights are in some circumstances limited by data-protection legislation. If you
            wish to exercise any of these rights please contact us at{' '}
            <strong>privacy@PfizerVirtualCafe.ie</strong>. We will take measures to verify your
            identify which we will do by reference to copies of acceptable identification
            documentation supplied by you.
          </p>
          <p>
            We will aim to respond to your request within one month. If we are unable to deal with
            your request within one month we may extend this period by a further two months and we
            will contact you and explain why.
          </p>
          <p>
            <strong>Last updated: 15 April 2021</strong>
          </p>
        </MainContent>
      </Main>
      <Footer />
    </>
  );
};

const MainContent = styled.div`
  ${flexContainer}
  flex-direction:column;
  font-family: noto-sans, tahoma, sans-serif;
  h2 {
    font-size: 2rem;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
  h3 {
    font-size: 1.5rem;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
  h4 {
    font-size: 1rem;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
  ul,
  ol {
    padding-left: 2rem;
  }
  p,
  li {
    font-size: 1rem;
    margin-bottom: 1.5em;
  }
  @media (min-width: 768px) {
    h2 {
      font-size: 3rem;
    }
    h3 {
      font-size: 1.75rem;
    }
    h4 {
      font-size: 1.25rem;
    }
  }
`;

const Main = styled(Section)`
  background: #00004e;
  color: white;
  padding: 3rem 1rem;
  @media (min-width: 768px) {
    padding: 5rem 3rem;
  }
`;

const Sponsor = styled(motion.div)`
  color: white;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  margin-bottom: 1.5rem;
  p {
    font-size: 1rem;
    color: white;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  svg {
    height: 5rem;
    max-width: 100%;
  }
  @media (min-width: 1150px) {
    margin-bottom: 2rem;
  }
`;

const Description = styled(motion.div)`
  color: white;
  font-size: 1.25rem;
  line-height: 1.3em;
  margin-bottom: 1.5rem;
  @media (min-width: 1150px) {
    margin-bottom: 4rem;
  }
`;

const AnimatedTitle = styled(motion.div)`
  color: white;
  font-size: 3rem;
  font-weight: bold;
  height: auto;
  margin-bottom: 0.25em;
  transform: rotateX(-90deg);
  transform-style: preserve-3d;
  width: auto;
  @media (min-width: 1150px) {
    font-size: 6rem;
  }
  svg {
    margin-bottom: 2rem;
    width: 15rem;
  }
  @media (min-width: 1150px) {
    svg {
      width: 30rem;
    }
    margin-bottom: 2rem;
  }
`;

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  justify-content: flex-start;
  padding: 2rem 0.5rem;
  position: relative;
  width: 100%;
  z-index: 2;
  @media (min-width: 768px) {
    padding: 4rem;
  }
  @media (min-width: 1150px) {
    grid-column: 2/8;
    padding: 0;
    padding-right: 5rem;
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const Background = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Container = styled.div`
  color: var(--black);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  margin: 0 auto 1rem;
  max-width: 1440px;
  padding: 5rem 0.625rem 2.5rem;
  position: relative;
  width: 100%;
  @media (min-width: 768px) and (min-height: 1000px) and (max-width: 1150px) {
    padding: 4rem 0;
    padding-top: 6rem;
    padding-bottom: 12.5rem;
  }
  @media (min-width: 1150px) {
    padding: 5rem 0;
    padding-top: 12.5rem;
    height: auto;
    margin-bottom: 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Header = styled.header`
  background: white;
  height: auto;
  position: relative;
  width: 100%;
  @media (min-width: 1150px) {
    min-height: 50vh;
  }
`;

export const query = graphql`
  query PrivacyQuery {
    background: file(relativePath: { eq: "assets/images/hub/hub-welcome-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export default Privacy;
